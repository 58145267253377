body {
  background: #01325b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.2;
}

footer {
  color: white;
  text-align: right;
  font-size: 10px;
  position: fixed;
  right: 5px;
  bottom: 5px;
}

header {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
}

header > div {
  display: flex;
  align-items: center;
  color: #ff0e00;
  max-width: 500px;
  width: 500px;
  padding: 10px 10px 10px 0;
}

header > * span {
  text-align: right;
  margin-left: auto !important;
  font-size: 20px;
}

form {
  max-width: 500px;
  margin: 0 auto;
  padding: 10px;
}

p {
  color: #bf1650;
}

p::before {
  display: inline;
  content: "⚠ ";
}

h2 {
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 24px;
  font-weight: 200;
}

.form-field-label > div + div {
  margin-top: 10px;
}

.form-field-label > label {
  margin-top: 2px;
  margin-bottom: 2px;
}

.form-field-controls {
  display: flex;
  align-items: flex-end;
}

.form-field-controls > span {
  flex: 1;
}

.btn-info {
  background-color: white;
  color: black;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
  margin-left: 6px;
  margin-bottom: 17px;
}

.btn-info:hover {
  background-color: #409dc3;
  color: white;
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 0;
  border: 1px solid #888;
  width: 95%; /* Could be more or less, depending on screen size */
  max-width: 450px;
  display: block;
}

.modal-img {
  width: 100%;
  height: 100%;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  resize: none;
}

label {
  text-align: left;
  display: block;
  margin-bottom: 8px;
  margin-top: 10px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

.checkbox-label {
  display: flex;
  height: 38px;
  align-items: center;
}

.checkbox-label > input {
  width: 19px;
  height: 19px;
  margin: 0;
}

.checkbox-label > span {
  flex: 1;
  margin-left: 10px;
  font-size: 16px;
}

.number-input-label {
  display: flex;
  height: 38px;
  align-items: center;
  justify-content: space-between;
}

.number-input-label > input {
  width: 120px;
  margin: 0;
}

.number-input-label > span {
  flex: 1;
  margin-left: 0px;
  font-size: 16px;
}

.select-label {
  justify-content: space-between;
  display: flex;
  height: 38px;
  align-items: center;
}

.select-label > select {
  width: 120px;
  margin: 0;
  height: 38px;
}

.select-label > span {
  flex: 0.5;
  margin-left: 0px;
  font-size: 16px;
}

button[type="submit"]:hover,
input[type="submit"]:hover {
  background: #bf1650;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

button[type="submit"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

button[type="button"] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
  background: #409dc3;
  color: white;
  font-size: 16px;
  font-weight: 100;
  margin-left: auto;
  cursor: pointer;
}

.optional-form-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
